/* eslint-disable react/prop-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-quotes */
import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { userInstance } from "../../config/axios";
import toastMessage from "../../functions/toastMessage";
import CancelConfirmation from "./CancelConfirmation";

const CancellationBilling = ({ userInformation, handleToggleSubscription }) => {
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const cancelSubscription = async () => {
    try {
      const response = await userInstance().post("/user/cancel-subscription");
      console.log("Cancel===>. Response ====>>", response);
      if (response?.status === 200) {
        toastMessage("success", response?.data?.message);
        window.location.href = "/subscription-information";
      }
    } catch (error) {
      console.log("error on cancel===>>", error);
    }
  };

  const handleConfirmation = () => {
    setConfirmationPopup(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationPopup(false);
  };

  return (
    <div className="target-heading  new-client-section flex-column">
      <CancelConfirmation
        openPaymentForm={confirmationPopup}
        handleAction={handleConfirmationClose}
        cancelSubscription={cancelSubscription}
      />
      <h1 className="data_heading">Cancel Subscription</h1>
      <Accordion className="w-100">
        <Card.Body>
          <ul className="subscription_billing staff-list top-client-details ">
            <li className="staff-list-item">
              <div className="billing-box">
                <h3>Subscription Duration</h3>
                <h5>{' '}
                  {userInformation?.duration
                    ? `${userInformation?.duration} Months`
                    : '10 Day Trial'}</h5>
              </div>
            </li>
            <li className="staff-list-item">
              <div className="billing-box">
                <h3>Start Date</h3>
                <h5>
                  {" "}
                  {moment(userInformation?.startsubscriptionDate).format(
                    "DD MMM YYYY",
                  )}
                </h5>
              </div>
            </li>
            <li className="staff-list-item">
              <div className="billing-box">
                <h3>End Date</h3>
                <h5>
                  {" "}
                  {moment(userInformation?.endsubscriptionDate).format(
                    "DD MMM YYYY",
                  )}

                </h5>
              </div>
            </li>
            <li className="staff-list-item">
              <div className="billing-box">
                <h3>No of Pools</h3>
                <h5>{userInformation?.numberOfPools}</h5>
              </div>
            </li>
            <li className="staff-list-item">
              <div className="billing-box">
                <h3>No of Users</h3>
                <h5>{userInformation?.numberUsers}</h5>
              </div>
            </li>
            <li className="staff-list-item">
              <div className="billing-box">
                <h3>Total Cost</h3>
                <h5>
                  {`${userInformation.country === 'Australia' ? 'AU$' : 'US$'}${userInformation?.subscriptionStatus === 'trial' ? '0' : userInformation.totalCost
                    }`}
                </h5>
              </div>
            </li>
          </ul>

          <div className="data_heading cancel_data mt-4">
            {userInformation?.subscriptionStatus === 'trial' ? (

              <div>
                <p>
                  We’re sorry to see you go!</p>

                <p>Before confirming, is there anything we can do to keep you? Our HelpBot or support team can quickly assist with any concerns.
                </p>
                <p>If you proceed, your free trial will end immediately, and you won’t be charged. Thank you for trying PoolWater-Pro!
                </p>
                <p>We welcome any feedback to help us improve—please feel free to share your thoughts at  <a href="/contact">
                  service@poolwater-pro.com
                </a>{" "}
                </p>
              </div>
            ) : (
              <div>
                <p>
                  We’re sorry to see you go!
                </p>
                <p>
                  Before confirming, is there anything we can do to keep you? Our HelpBot or support team can quickly assist with any concerns.
                </p>
                <p>
                  If you proceed, your free trial will end immediately, and you won’t be charged. Thank you for trying PoolWater-Pro!
                </p>
                <p>
                  We welcome any feedback to help us improve—please feel free to share your thoughts at&nbsp;
                  <a href="/contact">
                    service@poolwater-pro.com
                  </a>
                </p>
                <p></p>
              </div>
            )}

            <div className="row">
              <div className="col-md-12">
                <div className="view-btn staff-form-btn d-flex justify-content-between">
                  <Button
                    type="button"
                    className="btn btn-edit w-100"
                    onClick={handleConfirmation}
                  >
                    {" "}
                    Cancel Subscription
                  </Button>
                  {/* <Button
                    type='Submit'
                    onClick={() =>
                      handleToggleSubscription('Current Subscription')
                    }
                    className='btn btn-save btn-back'
                  >
                    Back
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Accordion>
    </div>
  );
};

export default CancellationBilling;

CancellationBilling.propTypes = {
  userInformation: PropTypes.string.isRequired,
};
