import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import DateModal from "../appointment/dateModal";
import { userInstance, withoutAuth } from "../../config/axios";

const SwimAlertPopup = ({
  setSwimAlertPopup,
  allOk,
  handleSendEmail,
  swimPopupMessage,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setSwimAlertPopup(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className={
        allOk
          ? "duration-picker time-picker-modal thankyou-popup swim-modal-green"
          : "duration-picker time-picker-modal thankyou-popup swim-modal-red"
      }
    >
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <h4>{allOk ? "SAFE TO SWIM" : "DON'T SWIM"}</h4>

            <div className="pending-water-test">
              {allOk ? (
                <>
                  {swimPopupMessage === "acid and 7.8" && (
                    <div className="line_space">
                      <h3>
                        Pool is Safe
                        <span className="font-italic">
                          {" "}
                          (except for adding acid).{" "}
                        </span>
                      </h3>
                      <p className="swim_para">
                        Add chemicals after swimming and run equipment for 3
                        hours minimum.
                      </p>
                    </div>
                  )}
                  {swimPopupMessage === "ph 7.5" && (
                    <div className="line_space">
                      <h3>
                        <span className="swim_para">
                          Your Pool is Safe to Swim.
                        </span>
                        <br /> <span className="swim_para">... Enjoy!</span>
                      </h3>
                      <p className="swim_para">
                        Add chemicals after swimming and run equipment for 3
                        hours minimum.
                      </p>
                    </div>
                  )}
                  {swimPopupMessage === "default" && (
                    <div className="line_space">
                      <h3>
                        <span className="swim_para">
                          Your Pool is Safe to Swim.
                        </span>
                        <br /> <span className="swim_para">... Enjoy!</span>
                      </h3>
                      <p className="swim_para">
                        Add chemicals after swimming and run equipment for 3
                        hours minimum.
                      </p>
                    </div>
                  )}
                  {swimPopupMessage === "allOkNew" && (
                    <div className="line_space">
                      <h3>
                        <span className="swim_para">
                          Your Pool is Safe to Swim.
                        </span>
                        <br /> <span className="swim_para">... Enjoy!</span>
                      </h3>
                      {/* <p className="swim_para">Add chemicals after swimming and run equipment for 3 hours minimum</p> */}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <>
                    {swimPopupMessage === "newCombinedChrlone" && (
                      <div className="line_space">
                        <h3>
                          Combined Chlorine Over 0.4ppm may cause irritation.
                        </h3>
                        <p className="swim_para para_spacing">
                          Add chemicals as recommended,
                          <br /> run equipment for a minimum of
                          <br /> 3 hours & re-test before swimming.
                        </p>
                      </div>
                    )}
                    {swimPopupMessage === "poolInputs.chlorine" && (
                      // <h3>
                      //   Add Chemicals as Recommended
                      //   <br />
                      //   & Re-test in 3 Hours.
                      // </h3>
                      <p className="swim_para para_spacing">
                        Add chemicals as recommended,
                        <br /> run equipment for a minimum of
                        <br /> 3 hours & re-test before swimming.
                      </p>
                    )}
                    {swimPopupMessage === "default" && (
                      <h3>
                        Add Chemicals as Recommended. Run Equipment & Re-test in
                        3 Hours.
                        
                      </h3>
                      // <p className="swim_para">
                      //   Add chemicals as recommended,
                      //   <br />
                      //   {' '}
                      //   run equipment for a minimum of
                      //   <br />
                      //   {' '}
                      //   3 hours & re-test before swimming.
                      // </p>
                    )}
                  </>
                </>
              )}
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => {
                  setSwimAlertPopup(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn  emil-me-button btn-save---"
                onClick={() => handleSendEmail(swimPopupMessage)}
              >
                Email Me
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SwimAlertPopup.propTypes = {
  setSwimAlertPopup: PropTypes.func.isRequired,
  allOk: PropTypes.bool.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  swimPopupMessage: PropTypes.string.isRequired,
};
export default SwimAlertPopup;
