import toastMessage from "../../functions/toastMessage";

export const billing_rules = [
  {
    duration: 0,
    includedPools: 1,
    includedUsers: 2,
    cost: 0,
    description: "Trial (= 10 days)",
  },
  {
    duration: 12,
    includedPools: 1,
    includedUsers: 2,
    cost: 179.4,
    description: "1 Year - $179.40 (= 12 months pre-paid)",
  },
  {
    duration: 24,
    includedPools: 1,
    includedUsers: 2,
    cost: 298.8,
    description: "2 Year - $298.80 (= 24 months pre-paid)",
  },
  {
    duration: 36,
    includedPools: 1,
    includedUsers: 2,
    cost: 358.2,
    description: "3 Year - $358.20 (= 36 months pre-paid)",
  },
];

export const billing_pools = [
  {
    num: 1,
    description: "1 Pool - Included",
  },
  {
    num: 2,
    description: "2 Pools - AU$5.00 per month",
  },
  {
    num: 3,
    description: "3 Pools - AU$10.00 per month",
  },
  {
    num: 4,
    description: "4 Pools - AU$15.00 per month",
  },
  {
    num: 5,
    description: "5 Pools - AU$20.00 per month",
  },
];

export const billing_users = [
  {
    num: 1,
    description: "1 User - Included",
  },
  {
    num: 2,
    description: "2 Users - Included",
  },
  {
    num: 3,
    description: "3 Users - AU$5.00 per month",
  },
  {
    num: 4,
    description: "4 Users - AU$10.00 per month",
  },
  {
    num: 5,
    description: "5 Users - AU$15.00 per month",
  },
];

export const durationCheck = (durationTime, duration, endsubscriptionDate) => {
  const condition =
    durationTime !== duration && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      "error",
      "You cannot change the subscription before Expiration Date",
    );
    return false;
  }
  return true;
};

export const poolCheck = (pools, oldPools, endsubscriptionDate) => {
  const condition =
    pools < oldPools && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    // toastMessage('error', 'You cannot downgrade the Number of Pools before Expiration Date');
    return false;
  }
  return true;
};

export const userCheck = (user, oldUsers, endsubscriptionDate) => {
  const condition =
    user < oldUsers && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    // toastMessage('error', 'You cannot downgrade the Number of Users before Expiration Date');
    return false;
  }
  return true;
};

export const checkRemainTime = (endDate) => {
  const endDateObject = new Date(endDate);
  const currentDate = new Date();
  // +1 was added in order to include the current month also
  const monthsDiff =
    (endDateObject.getFullYear() - currentDate.getFullYear()) * 12 +
    (endDateObject.getMonth() - currentDate.getMonth());

  return monthsDiff;
};
