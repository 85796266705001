import React, { useState, useEffect } from "react";
import "./contact.css";
import { Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { userInstance } from "../../config/axios";
import Layout from "../layout/index";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import { validateDataWithKey } from "../../functions/index";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import Loader from "../loader/loader";

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [submitStatus, setSubmitStatus] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
    setSubmitStatus(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { companyName, firstName, lastName, email, message } = contactInfo;

    const validationObj = {
      firstName,
      lastName,
      email,
      message,
    };
    const { isValid, emptyField } = validateDataWithKey(validationObj);

    if (isValid) {
      const payload = {
        companyName,
        firstName,
        lastName,
        email: email.toLowerCase(),
        message,
      };
      const response = await userInstance().put(
        "/user/updateContactDetails",
        payload,
      );
      const { msg } = response.data;
      NotificationManager.success(msg, "Message", successMsgTime);
      getContactDetails();
      setSubmitStatus(true);
    } else {
      setErrorValue(emptyField);
      NotificationManager.error(
        `Please fill ${emptyField}`,
        "Message",
        errorMsgTime,
      );
    }
    setTimeout(() => {
      setErrorValue("");
    }, 1000);
  };

  const getContactDetails = async () => {
    try {
      const response = await userInstance().get("/user/getContactDetails");
      setLoading(false);
      const { userDetails } = response.data;
      if (userDetails) {
        const { contact } = userDetails;
        if (contact) {
          setContactInfo(contact);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error==>>", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getContactDetails();
  }, []);

  return (
    <AdminWrapper>
      {loading ? (
        <Loader />
      ) : (
        <div className="contact-page pool-set-up">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Contact</h3>
                </div>
              </div>
            </div>

            <div className="contact-container client-main-contact">
              <div className="title">
                <p>
                  For quicker answers try HelpBot, our customised ChatGPT pool
                  industry specialist, available 24/7.
                  {/* Please utilise the form on this page to ask any questions you have about
                  PoolWater-Pro. */}
                </p>
                <p>
                  From the main contents menu, select HelpBot, type your
                  question and press enter to get a reply.
                </p>
                <p>
                  If you need a human response, please utilise the form below to
                  contact our support team directly.
                </p>
                <p>
                  Alternatively you can email us direct at
                  <a href="mailto: service@poolwater-pro.com?subject=CONTACT from PoolWater-Pro">
                    {" "}
                    service@poolwater-pro.com
                  </a>
                </p>
              </div>

              <div className="contact-form staff-form">
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    {/* <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-1">
                        <Form.Label>Company name</Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          value={contactInfo.companyName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div> */}
                    <div className="col-md-6">
                      <Form.Group
                        controlId="formBasicInput-1"
                        className="italic-text"
                      >
                        <Form.Label>
                          {/* Contact&apos;s  */}
                          First Name {/* <span>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={contactInfo.firstName}
                          onChange={handleChange}
                          placeholder="Enter your first name"
                          className={`${
                            errorValue === "firstName" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        controlId="formBasicInput-1"
                        className="italic-text"
                      >
                        <Form.Label>
                          {/* Contact&apos;s  */}
                          Last Name {/* <span>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={contactInfo.lastName}
                          onChange={handleChange}
                          placeholder="Enter your last name"
                          className={`${
                            errorValue === "lastName" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        controlId="formBasicInput-1"
                        className="italic-text"
                      >
                        <Form.Label>
                          {/* Contact&apos;s  */}
                          Email Address {/* <span>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={contactInfo.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                          className={`${
                            errorValue === "email" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlTextarea1"
                        className="italic-text"
                      >
                        <Form.Label>
                          Message
                          {/* <span>*</span> */}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="message"
                          value={contactInfo.message}
                          onChange={handleChange}
                          placeholder="Write your message"
                          className={`${
                            errorValue === "message" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="view-btn">
                        <Button
                          type="submit"
                          className="btn btn-save btn btn-primary"
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};

export default Contact;
