import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';

// const DatePopupModal = (props) => {
//   const { datePopup, setDatePopup, handleDatePopup, selectedStartDate, selectedEndDate } = props;

//   const handleModalClose = () => {
//     setDatePopup(false);
//   };

//   return (
//     <Modal
//       size="md"
//       show={datePopup}
//       onHide={handleModalClose}
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
//     >
//       <Modal.Header closeButton />
//       <Modal.Body>
//         <div className="search-popup shift-container">
//           <div className="staff-close">
//             <i
//               className="fa fa-times"
//               onClick={handleModalClose}
//               aria-hidden="true"
//             />
//           </div>
//           <div className="time-calender">
//             {selectedStartDate && (
//             <Calendar
//               onChange={handleDatePopup}
//               value={new Date(selectedStartDate)}
//             />
//             )}
//             {selectedEndDate && (
//             <Calendar
//               onChange={handleDatePopup}
//               value={new Date(selectedEndDate)}
//               minDate={new Date(Date.now())}
//               />
//             )}

//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// DatePopupModal.propTypes = {
//   datePopup: PropTypes.func.isRequired,
//   setDatePopup: PropTypes.func.isRequired,
//   handleDatePopup: PropTypes.func.isRequired,
//   selectedStartDate: PropTypes.string.isRequired,
//   selectedEndDate: PropTypes.string.isRequired,
// };

// export default DatePopupModal;


const DatePopupModal = ({
  datePopup,
  setDatePopup,
  handleDatePopup,
  selectedStartDate,
  selectedEndDate,
  isSelectingStartDate,
}) => {
  const handleModalClose = () => {
    setDatePopup(false);
  };

  return (
    <Modal
      size="md"
      show={datePopup}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="search-popup shift-container">
          <div className="staff-close">
            <i
              className="fa fa-times"
              onClick={handleModalClose}
              aria-hidden="true"
            />
          </div>
          <div className="time-calender">
            {/* <Calendar
              onChange={handleDatePopup}
              value={isSelectingStartDate ? (selectedStartDate ? new Date(selectedStartDate) : new Date()) : (selectedEndDate ? new Date(selectedEndDate) : new Date())}
              // minDate={isSelectingStartDate ? new Date() : selectedStartDate ? new Date(selectedStartDate) : new Date()}
            /> */}
            <Calendar
              onChange={handleDatePopup}
              value={isSelectingStartDate 
                ? (selectedStartDate ? new Date(selectedStartDate) : new Date()) 
                : (selectedEndDate ? new Date(selectedEndDate) : new Date())}
                // maxDate={isSelectingStartDate ? (selectedEndDate ? new Date(selectedEndDate) : new Date()) : new Date()}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DatePopupModal.propTypes = {
  datePopup: PropTypes.bool.isRequired,
  setDatePopup: PropTypes.func.isRequired,
  handleDatePopup: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string,
  selectedEndDate: PropTypes.string,
  isSelectingStartDate: PropTypes.bool.isRequired,
};

export default DatePopupModal;
