import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SyncLoader } from "react-spinners";
import markdownit from "markdown-it";
import DOMPurify from "dompurify";
import { isMobile } from "react-device-detect";
import { VscDebugRestart } from "react-icons/vsc";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { chatPath } from "../../config/keys";
import { Controls } from "./controls";
import "./index.css";
import { HUMAN_SUPPORT_MODE, NORMAL_MODE } from "../chatbotSettings/contants";
import generateId from "../../functions/generateId";

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
});

const parseGreeting = (greeting) => {
  let result = "";
  if (isMobile) {
    result = greeting.replaceAll("#-w-b", "\n").replaceAll("#-m-b", "\n");
  } else {
    result = greeting.replaceAll("#-w-b", "\n").replaceAll("#-m-b", "");
  }
  return result;
};

const ChatBot = (props) => {
  const { handleClose, countryName, chatbotInfos } = props;
  const [messages, setMessages] = useState([
    {
      id: generateId(7),
      type: "bot",
      text: parseGreeting(chatbotInfos.greeting),
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [allContent, setAllContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const messagesEndRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const [mode, setMode] = useState(NORMAL_MODE);
  const [userEmailaddress, setUserEmailAddress] = useState("");
  const [emailContent, setEmailContent] = useState("");

  useEffect(() => {
    scrollToBottom();
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.type === "bot") {
      setIsLoading(false);
      if (lastMessage.text.includes("/human_support")) {
        setMode(HUMAN_SUPPORT_MODE);
      }
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  };

  useEffect(
    () => {
      scrollToBottom();
    },
    [messages, mode, isLoading]
  );

  const sendQuestion = () => {
    if (input.trim() === "" || isLoading) return;

    setIsChat(true);
    setIsLoading(true);
    setErrorMessage("");

    const newMessage = {
      id: generateId(7),
      type: "user",
      text: input,
    };

    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    scrollToBottom();
    sendMessages(updatedMessages);
  };

  const sendMessages = async (updatedMessages) => {
    setIsLoading(true); // Start loading
    setInput("");
    try {
      const systemPrompt = `Here is User's information:\n${countryName}\n`;
      console.log("sendmessage----", updatedMessages);

      const response = await fetch(`${chatPath}/webapp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("s#@4f")
            ? `${"Bearer "}${localStorage.getItem("s#@4f")}`
            : "",
          authtoken: localStorage.getItem("f#@5e")
            ? `${"Bearer "}${localStorage.getItem("f#@5e")}`
            : "",
        },
        timeout: 1000 * 20,
        body: JSON.stringify({
          prefix: systemPrompt,
          messages: updatedMessages,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let accumulatedText = ""; // Declare outside loop

      const processChunk = (chunk) => {
        accumulatedText += chunk;
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage.type === "user") {
            return [
              ...prevMessages,
              {
                id: generateId(7),
                type: "bot",
                text: accumulatedText, // Use captured value
              },
            ];
          }
          if (lastMessage.type === "bot") {
            const updatedMessagesList = [...prevMessages];
            updatedMessagesList[updatedMessagesList.length - 1].text =
              accumulatedText;
            return updatedMessagesList;
          }
          return prevMessages;
        });
      };
      setIsLoading(false);
      await (async () => {
        while (true) {
          // eslint-disable-line no-constant-condition
          const { done, value } = await reader.read(); // eslint-disable-line no-await-in-loop
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          processChunk(chunk); // Call function instead of inline state update
        }
      })();
    } catch (error) {
      console.error("Chatbot Error:", error.message);
      setErrorMessage(
        "There was an error sending this message. If the issue persists, email the support team at service@poolwaterpro.com."
      );
      setIsLoading(false);
    } finally {
      setIsChat(false);
    }
  };
  const SendingEmail = async () => {
    if (emailContent === "") {
      alert("Please type your issues in the message box.");
      return;
    }
    if (userEmailaddress === "") {
      alert("Please input your email address.");
      return;
    }
    try {
      setIsChat(true);
      setIsLoading(true);
      setMode(NORMAL_MODE);
      let response = await axios.post(`${chatPath}/support/send-email`, {
        user_email: userEmailaddress,
        user_question: emailContent,
      });

      setEmailContent("");
      setUserEmailAddress("");

      if (response.status === 200) {
        const aiNewMessage = {
          type: "bot",
          text: `Successfully sent!</br></br>Here’s a summary of your email:</br><pre>${emailContent}</pre></br>Please check your email inbox. You will get a reply within 24 hours.`,
        };
        let _messages = messages.slice();
        _messages.splice(_messages.length - 1, 1);
        _messages.push(aiNewMessage);
        setMessages(_messages);
      } else {
        const aiNewMessage = {
          type: "user",
          text: `Failed to send an email to support team.`,
        };
        let _messages = messages.slice();
        _messages[_messages.length - 1] = aiNewMessage;
        setMessages(_messages);
      }
    } catch (error) {
      console.log("error", error);
      alert(error.message);
    }
    setIsChat(false);
    setIsLoading(false);
  };

  const handleKeyDown = (e) => {
    if (!isMobile) {
      if (e.key === "Enter" && e.shiftKey) {
        e.preventDefault();
        setInput(`${input}\n`);
      } else if (e.key === "Enter") {
        e.preventDefault();
        sendQuestion();
      }
    }
  };

  const handleClearChathistory = () => {
    setMessages([
      {
        id: generateId(7),
        type: "bot",
        text: parseGreeting(chatbotInfos.greeting),
      },
    ]);
    setMode(NORMAL_MODE);
    setErrorMessage("");
  };

  const handleCommand = () => {
    if (mode === HUMAN_SUPPORT_MODE) {
      SendingEmail();
    }
  };
  const handleInputChange = (e) => setInput(e.target.value);
  return (
    <section className="avenue-messenger">
      <div className="menu">
        <VscDebugRestart
          className="chatbot-menu-button"
          onClick={handleClearChathistory}
          size={22}
          color="#7a7b7f"
        />
        <IoMdClose
          className="chatbot-menu-button"
          onClick={handleClose}
          size={24}
          color="#7a7b7f"
        />
      </div>
      <div className="agent-face">
        <div className="half">
          <img
            className="agent chatbot-circle"
            src={chatbotInfos.avatar}
            alt="Jesse Tino"
          />
        </div>
      </div>
      <div
        className="chat"
        style={{
          height: isMobile ? "calc( 100% - 33px )" : "calc( 100% - 15px )",
        }}
      >
        <div className="chat-title">
          <span>{chatbotInfos.name}</span>
        </div>
        <div className="messages">
          <Scrollbars
            ref={scrollbarsRef}
            className="messages-content"
            onScroll={(e) => {
              e.stopPropagation();
            }}
          >
            {messages.length > 0 &&
              messages.map((msg, index) => (
                <>
                  {msg.text.includes("/human_support") ? (
                    <>
                      {mode === HUMAN_SUPPORT_MODE && (
                        <div key={msg.id} className="message new">
                          <div>
                            <figure className="avatar">
                              <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                            </figure>
                            <div className="message-box-content py-1">
                              <p>
                                Please email your issue to support team.
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </p>
                              <div className="mb-1 mt-1">
                                <label htmlFor="email-address-box">
                                  Your Email:
                                </label>
                                <input
                                  id="email-address-box"
                                  className="email-address-box"
                                  type="email"
                                  value={userEmailaddress}
                                  onChange={(e) =>
                                    setUserEmailAddress(e.target.value)
                                  }
                                />
                              </div>
                              <div>
                                <p>Message:</p>

                                <textarea
                                  className="email-content-box"
                                  rows={6}
                                  placeholder="Type your issue here…"
                                  value={emailContent}
                                  onChange={(e) => {
                                    setEmailContent(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      key={msg.id}
                      className={`message ${msg.type === "user" ? "message-personal" : "new"
                        }`}
                    >
                      {msg.type === "bot" && (
                        <figure className="avatar">
                          <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                        </figure>
                      )}
                      <div
                        className="message-box-content"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(md.render(msg.text)),
                        }}
                      />
                    </div>
                  )}
                </>
              ))}
            {isChat && isLoading && (
              <div className="message new">
                <figure className="avatar">
                  <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                </figure>
                <SyncLoader
                  size={6}
                  color="grey"
                  loading={isLoading}
                  speedMultiplier={0.7}
                />
              </div>
            )}
            {errorMessage && (
              <div className="message new">
                <figure className="avatar">
                  <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                </figure>
                <div
                  className="message-box-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(md.render(errorMessage)),
                  }}
                />
              </div>
            )}
            <div ref={messagesEndRef} />
          </Scrollbars>
        </div>
        <Controls
          setError={setErrorMessage}
          handleCommand={handleCommand}
          handleSubmit={sendQuestion}
          handleInputChange={handleInputChange}
          input={input}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          mode={mode}
          setMode={setMode}
        />
      </div>
    </section>
  );
};

ChatBot.propTypes = {
  handleClose: PropTypes.func.isRequired,
  countryName: PropTypes.string.isRequired,
  chatbotInfos: PropTypes.objectOf.isRequired, // Fixed typo: objectOf to object
};

export default ChatBot;
