// help, taken from,
// https://github.com/vercel/ai-chatbot/blob/fa9f0947f0a7983cf5022cbbc1416910349dd5e4/lib/hooks/use-enter-submit.tsx
import { useRef } from "react";

export function useSubmit() {
  const formRef = useRef(null);

  const handleKeyDown = (event) => {
    if (
      !event.shiftKey &&
      !event.nativeEvent.isComposing &&
      event.key === "Enter"
    ) {
      formRef.current?.requestSubmit();
      event.preventDefault();
    }
  };

  return { formRef, onKeyDown: handleKeyDown };
}
