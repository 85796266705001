import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { IoIosSave } from 'react-icons/io';
import TextField from '../components/textField';
import Button from '../components/button';
import LoadingButton from '../components/loadingButton';
import toastMessage from '../../../functions/toastMessage';
import { chatPath } from '../../../config/keys';
import { userInstance } from '../../../config/axios';

const toolbarConfig = {
  options: ['image', 'emoji', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code'],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify'],
  },
};

// Inline style objects
const editorStyle = {
  border: '1px solid rgb(204, 204, 204)',
  padding: '4px',
  borderRadius: '4px',
  minHeight: '200px',
  overflowY: 'auto',
  background: 'white',
  maxHeight: '40vh',
};

const toolbarStyle = {
  border: '1px solid rgb(204, 204, 204)',
  borderRadius: '4px',
};

const AddTextModal = (props) => {
  const {
    show,
    handleClose,
    uploadedTexts,
    setUploadedTexts,
    getUploadedTexts,
    scope,
    isEditMode,
    selectedItem,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleChangeTitle = (value) => {
    setTitle(value);
  };

  const handleSaveText = async () => {
    if (title === "") {
      toastMessage("error", "Please input the title.");
      return;
    }

    if (editorState === EditorState.createEmpty()) {
      toastMessage('error', 'Please input the content.');
      return;
    }

    const { rawContent, extractedText } = getContentFromEditorState();

    try {
      setIsSaving(true);

      if (!isEditMode) {
        let response = await userInstance().post(`${chatPath}/chatbotSettings/uploadText`, {
          title, content: rawContent, textContent: extractedText, scope
        });
        setIsSaving(false);
        const { code, msg } = response.data;
        if (code === 200) {
          const { uploadedText } = response.data;
          setUploadedTexts((prev) => [...prev, uploadedText]); // Inline return without curly braces
          toastMessage("success", msg);
        } else {
          toastMessage("error", msg);
        }

        initialize();
        handleClose();
      } else {
        let response = await userInstance().post(`${chatPath}/chatbotSettings/updateUploadedText`, {
          id: selectedItem._id, title, content: rawContent, textContent: extractedText, scope
        });
        const { code, msg } = response.data;
        setIsSaving(false);
        if (code === 200) {
          const { updatedUploadedText } = response.data;
          let _uploadedTexts = uploadedTexts.slice();
          let findIndex = _uploadedTexts.findIndex(
            (item) => item._id === updatedUploadedText._id,
          );
          if (findIndex !== -1) {
            _uploadedTexts[findIndex].title = updatedUploadedText.title;
            _uploadedTexts[findIndex].content = updatedUploadedText.content;
            setUploadedTexts(_uploadedTexts);
          }
          toastMessage("success", msg);
        } else {
          toastMessage("error", msg);
        }
        initialize();
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const initialize = () => {
    setTitle('');
    setEditorState(EditorState.createEmpty());
  };

  const handleCloseModal = () => {
    initialize();
    handleClose();
  };

  useEffect(() => {
    if (selectedItem !== null) {
      if (isEditMode) {
        setTitle(selectedItem.title);
        const contentState = convertFromRaw(selectedItem.content);
        setEditorState(EditorState.createWithContent(contentState));
      } else {
        initialize();
      }
    } else {
      initialize();
    }
  }, [selectedItem, isEditMode, show]);

  // Editor
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const getContentFromEditorState = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    const { blocks, entityMap } = rawContent;
    let extractedText = '';

    blocks.forEach((block) => {
      const { text } = block;
      extractedText += `${text}\n`;

      // Check for entities in the block
      block.entityRanges.forEach((entityRange) => {
        const entityKey = entityRange.key;
        const entity = entityMap[entityKey];
        if (entity.type === 'IMAGE') {
          const imageUrl = entity.data.src;
          extractedText += `Image: ${imageUrl}\n`;
        }
      });
    });

    return { rawContent, extractedText };
  };

  return (
    <>
      <Modal
        show={show}
        centered
        dialogClassName="chatbot-settings-modal-container modal-90w custom-modal-height"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        styl
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {isEditMode ? "Edit Text" : "Add Text"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="d-flex flex-column gap-3">
            <TextField
              type="text"
              label="Title"
              placeholder=""
              value={title}
              handleChangeValue={handleChangeTitle}
            />
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              toolbar={toolbarConfig}
              editorStyle={editorStyle}
              toolbarStyle={toolbarStyle}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button
              label="Cancel"
              handleClickBtn={handleCloseModal}
              disabled={isSaving}
            />
            <LoadingButton
              isLoading={isSaving}
              icon={<IoIosSave size={18} style={{ marginRight: "16px" }} />}
              label="Save"
              handleClickBtn={handleSaveText}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddTextModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  uploadedTexts: PropTypes.arrayOf.isRequired,
  setUploadedTexts: PropTypes.func.isRequired,
  getUploadedTexts: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  selectedItem: PropTypes.objectOf.isRequired,
};

export default AddTextModal;
