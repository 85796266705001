import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const AuthDataContext = createContext();

const AuthDataProvider = (props) => {
  const { children } = props;
  const [locationsInfo, setLocationInfo] = useState();
  const [actionName, setActionName] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    auth: null,
    accessLevel: "",
    permissions: [],
    firstLogin: null,
    showHeader: false,
    calendarSettings: {},
    numberUsers: "",
    numberOfPools: "",
    isOwner: false,
    userPermissions: [],
    role: "",
    userDetails: {},
    industryId: "",
    invoicePhone: "",
    istwilio: "",
    invoiceEmail: "",
    isEmail: "",
    isNumberVerify: "",
    isEmailVerify: "",
    chemicalsToDisplay: false,
    userInformation: null,
  });
  const [clientAllData, setClientAllData] = useState({
    appointmentData: [],
    addedServices: [],
    eventDate: "",
    activeStaff: null,
    customerDues: 0,
    clientAllDetails: null,
    waterTestHistory: {},
  });
  const [testingPurpose, setTestingPurpose] = useState({
    toggle:""
  });

  return (
    <AuthDataContext.Provider
      value={{
        userData,
        setUserData,
        clientAllData,
        setClientAllData,
        testingPurpose,
        setTestingPurpose,
        locationsInfo,
        setLocationInfo,
        setActionName,
        actionName,
      }}
    >
      {children}
    </AuthDataContext.Provider>
  );
};

AuthDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthDataProvider;
