import { useCallback, useEffect, useState } from "react";
import { useSubmit } from "../../functions/hooks/useSubmit";
import TextareaAutosize from "react-textarea-autosize";
import { FaArrowCircleUp } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { NORMAL_MODE } from "../chatbotSettings/contants";

export const Controls = ({
  setError,
  input,
  mode,
  handleSubmit,
  handleCommand,
  handleInputChange,
  isLoading,
  setIsLoading,
  setMode,
}) => {
  const { formRef, onKeyDown } = useSubmit();

  const submitter = useCallback(
    (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
      setError("");
      if (mode === NORMAL_MODE) {
        setIsLoading(true);
        handleSubmit();
      } else {
        handleCommand();
      }
      e.target.value = "";
      handleInputChange(e);
    },
    [
      handleSubmit,
      mode,
      handleCommand,
      setIsLoading,
      setError,
      handleInputChange,
    ]
  );

  const [textAreaClass, setTextAreaClass] = useState(
    isMobile ? "message-input-mobile" : "message-input"
  );

  useEffect(() => {
    if (isMobile) {
      setTextAreaClass("message-input-mobile");
    } else {
      setTextAreaClass("message-input");
    }
  }, [isMobile]);

  return (
    <form onSubmit={submitter} ref={formRef}>
      <div className="message-box">
        <TextareaAutosize
          onPointerDown={() => {
            if (mode !== NORMAL_MODE) {
              if (
                window.confirm("Discard email sending & continue with HelpBot?")
              )
                setMode(NORMAL_MODE);
            }
          }}
          onKeyDown={onKeyDown}
          rows={1}
          maxRows={10}
          spellCheck={false}
          autoCorrect="off"
          className={textAreaClass}
          placeholder={mode === NORMAL_MODE ? "Type your question here…" : ""}
          value={input}
          onChange={handleInputChange}
          disabled={mode !== NORMAL_MODE || isLoading}
        />
        <button type="submit" className="message-submit" disabled={isLoading}>
          <FaArrowCircleUp size={28} color="black" />
        </button>
      </div>
    </form>
  );
};
